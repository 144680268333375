import { DisplayTextComponent } from '../display/text/display-text.component';
import { EditTextComponent } from '../edit/text/edit-text.component';
import { EditTableComponent } from '../edit/table/edit-table.component';
import { DisplayChartComponent } from '../display/chart/display-chart.component';
import { Injectable, Type } from '@angular/core';
import { NodeRepresentation } from '../../interfaces/node-representation.interface';
import { UnknownNodeComponent } from '../node-unknown/node-unknown.component';
import { DisplayTableComponent } from '../display/table/display-table.component';
import { EditImageComponent } from '../edit/image/edit-image.component';
import { DisplayImageComponent } from '../display/image/display-image.component';
import { EditImageGridComponent } from '../edit/imagegrid/edit-imagegrid.component';
import { DisplayImageGridComponent } from '../display/imagegrid/display-imagegrid.component';
import { DisplayPageBreakComponent } from '../display/page-break/display-page-break.component';
import { DisplayColBreakComponent } from '../display/col-break/display-col-break.component';
import { DisplayTableOfContentComponent } from '../display/table-of-content/display-table-of-content.component';
import { DisplayTwoColsStartComponent } from '../display/two-cols-start/display-two-cols-start.component';
import { DisplayTwoColsEndComponent } from '../display/two-cols-end/display-two-cols-end.component';
import { DisplayLineComponent } from '../display/line/display-line.component';
import { EditKeyFiguresComponent } from '../edit/keyfigures/edit-keyfigures.component';
import { DisplayKeyfiguresComponent } from '../display/keyfigures/display-keyfigures.component';
import { DisplayExcelTableComponent } from '../display/excel-table/display-excel-table.component';
import { TranslateService } from '@ngx-translate/core';
import { NodeConfig, NodeType } from '../../models/node/node-type.model';
import { DisplayQuoteComponent } from '../display/quote/display-quote.component';
import { EditQuoteComponent } from '../edit/quote/edit-quote.component';
import { SystemInformationQuery } from '../../../../modules/shared/state/system-information/system-information.query';
import { EditTitleComponent } from '../edit/title/edit-title.component';
import { EditLeadComponent } from '../edit/lead/edit-lead.component';
import { EditFootnoteComponent } from '../edit/footnote/edit-footnote.component';
import { EditDisclaimerComponent } from '../edit/disclaimer/edit-disclaimer.component';
import { DisplayExcelChartComponent } from '../display/excel-chart/display-excel-chart.component';

export interface NodeComponent {
    component: Type<NodeRepresentation>;
    config?: NodeConfig;
}

export type NodeComponentMap = {
    [k in NodeType]: {
        editor: NodeComponent;
        display: NodeComponent;
    };
};

@Injectable({
    providedIn: 'root',
})
export class NodeComponents {
    private locale = 'de_CH';

    constructor(private systemInformationQuery: SystemInformationQuery, private translateService: TranslateService) {}

    public getConfiguration(locale?: string): NodeComponentMap {
        if (locale) {
            this.locale = locale.replace('-', '_');
        }

        let textCustomElements = '';
        let textComponentValidElements =
            'a[title|href|target],p,ul[style],ol[style],li,b,strong,i,u,sub,sup,em,br,span[style]';

        if (this.systemInformationQuery.getValue().featureFlags['legacy_formats']) {
            textComponentValidElements += ',h2,h3,h4,h5,h6,hr[class],abbr[*],small[*],dfn[*],blockquote';
            textCustomElements = 'abbr,small,dfn';
        }

        return {
            text: {
                editor: {
                    component: EditTextComponent,
                    config: {
                        type: 'text',
                        settings: {
                            quickbars_selection_toolbar:
                                'bold italic underline | link | subscript superscript | numlist bullist | nonbreaking | backcolor',
                            spellchecker_language: this.locale,
                            valid_elements: textComponentValidElements,
                            custom_elements: textCustomElements, // This doesn't nest them in <p> tag
                        },
                    },
                },
                display: {
                    component: DisplayTextComponent,
                    config: { type: 'text' },
                },
            },
            'key-figure': {
                editor: {
                    component: EditKeyFiguresComponent,
                    config: {
                        type: 'key-figure',
                    },
                },
                display: {
                    component: DisplayKeyfiguresComponent,
                    config: { type: 'key-figure' },
                },
            },
            title: {
                editor: {
                    component: EditTitleComponent,
                    config: {
                        type: 'title',
                        settings: {
                            quickbars_selection_toolbar: 'link | subscript superscript | nonbreaking ',
                            paste_word_valid_elements: 'a,sub,sup,br,p',
                            valid_elements: 'a[title|href|target],sub,sup,p,br,hr',
                            spellchecker_language: this.locale,
                            valid_styles: {},
                            paste_preprocess(plugin, args) {
                                args.content = args.content.replace(/<br>/g, ' ');
                            },
                        },
                    },
                },
                display: {
                    component: DisplayTextComponent,
                    config: { type: 'title' },
                },
            },
            lead: {
                editor: {
                    component: EditLeadComponent,
                    config: {
                        type: 'lead',
                        settings: {
                            quickbars_selection_toolbar: 'subscript superscript | nonbreaking | backcolor',
                            paste_word_valid_elements: 'p,sub,sup,br,span',
                            valid_elements: 'p,sub,sup,br,span[style]',
                            plugins: ['paste', 'nonbreaking', 'colbreak', 'quickbars', 'visualchars', 'spellchecker'],
                            spellchecker_language: this.locale,
                            valid_styles: {
                                span: 'color,background-color',
                            },
                            color_map: ['FF0000', 'Red', 'FFFF00', 'Yellow'],
                        },
                    },
                },
                display: {
                    component: DisplayTextComponent,
                    config: { type: 'lead' },
                },
            },
            footnote: {
                editor: {
                    component: EditFootnoteComponent,
                    config: {
                        type: 'footnote',
                        settings: {
                            quickbars_selection_toolbar: 'bold italic | subscript superscript | link',
                            paste_word_valid_elements: 'p,sup,sub,b,strong,em,i,br,a',
                            valid_elements: 'p,sup,sub,b,strong,em,i,br,a[title|href|target]',
                            spellchecker_language: this.locale,
                            valid_styles: {},
                        },
                    },
                },
                display: {
                    component: DisplayTextComponent,
                    config: { type: 'footnote' },
                },
            },
            disclaimer: {
                editor: {
                    component: EditDisclaimerComponent,
                    config: {
                        type: 'disclaimer',
                        settings: {
                            quickbars_selection_toolbar: 'bold',
                            paste_word_valid_elements: 'b,bold,br',
                            valid_elements: 'p,b,strong,br',
                            valid_styles: {},
                            spellchecker_language: this.locale,
                        },
                    },
                },
                display: {
                    component: DisplayTextComponent,
                    config: { type: 'disclaimer' },
                },
            },
            'excel-table': {
                editor: {
                    component: DisplayExcelTableComponent,
                    config: {
                        type: 'excel-table',
                        settings: { toolbar: false },
                    },
                },
                display: {
                    component: DisplayExcelTableComponent,
                    config: { type: 'excel-table' },
                },
            },
            image: {
                editor: {
                    component: EditImageComponent,
                    config: {
                        type: 'image',
                        settings: { toolbar: false },
                    },
                },
                display: {
                    component: DisplayImageComponent,
                    config: { type: 'image' },
                },
            },
            'image-grid': {
                editor: {
                    component: EditImageGridComponent,
                    config: {
                        type: 'image-grid',
                        settings: { toolbar: false },
                    },
                },
                display: {
                    component: DisplayImageGridComponent,
                    config: { type: 'image-grid' },
                },
            },
            quote: {
                editor: {
                    component: EditQuoteComponent,
                    config: {
                        type: 'quote',
                        settings: {
                            plugins: ['spellchecker', 'paste', 'quickbars'],
                            toolbar: 'spellchecker',
                            fixed_toolbar_container: '#toolbar-content',
                            quickbars_selection_toolbar: '',
                            spellchecker_language: this.locale,
                            valid_elements: 'blockquote,sub,sup,p,br',
                            custom_elements: '',
                        },
                    },
                },
                display: {
                    component: DisplayQuoteComponent,
                    config: { type: 'quote' },
                },
            },
            table: {
                editor: {
                    component: EditTableComponent,
                    config: {
                        type: 'table',
                        settings: {
                            colHeaders: false,
                            fillHandle: true,
                            stretchH: 'last',
                            copyPaste: true,
                            colWidths: function (index) {
                                return index > 1 ? 200 : 100;
                            },
                            contextMenu: {
                                items: {
                                    row_above: {
                                        name: this.translateService.instant('document.nodeEditable.row_above'),
                                    },
                                    row_below: {
                                        name: this.translateService.instant('document.nodeEditable.row_below'),
                                    },
                                    separator1: {
                                        name: '---------',
                                    },
                                    col_left: {
                                        name: this.translateService.instant('document.nodeEditable.col_left'),
                                    },
                                    col_right: {
                                        name: this.translateService.instant('document.nodeEditable.col_right'),
                                    },
                                    separator2: {
                                        name: '---------',
                                    },
                                    remove_row: {
                                        name: this.translateService.instant('document.nodeEditable.remove_row'),
                                    },
                                    remove_col: {
                                        name: this.translateService.instant('document.nodeEditable.remove_col'),
                                    },
                                },
                            },
                            cells: (row, col, prop) => {
                                const cellProperties: any = {};

                                if ((row === 0 && col === 0) || (row === 1 && col === 1)) {
                                    cellProperties.readOnly = true;
                                    cellProperties.renderer = function (
                                        instance,
                                        td,
                                        row,
                                        col,
                                        prop,
                                        value,
                                        cellProperties
                                    ) {
                                        td.style.background = '#eeeeee';
                                        td.innerHTML = value;
                                        return td;
                                    };
                                } else if (row === 0 || row === 1 || col === 0 || col === 1) {
                                    cellProperties.renderer = function (
                                        instance,
                                        td,
                                        row,
                                        col,
                                        prop,
                                        value,
                                        cellProperties
                                    ) {
                                        td.style.background = '#f8f9fa';
                                        td.innerHTML = value;
                                        return td;
                                    };
                                }

                                return cellProperties;
                            },
                        },
                    },
                },
                display: {
                    component: DisplayTableComponent,
                    config: {
                        type: 'table',
                    },
                },
            },
            'line-chart': {
                editor: {
                    component: EditTableComponent,
                    config: {
                        type: 'line-chart',
                        settings: {
                            colHeaders: [this.translateService.instant('document.nodeEditable.xaxis')],
                            rowHeaders: [this.translateService.instant('document.nodeEditable.legend')],
                            minRows: 3,
                            minCols: 2,
                            contextMenu: {
                                items: {
                                    row_above: {
                                        name: this.translateService.instant('document.nodeEditable.row_above'),
                                    },
                                    row_below: {
                                        name: this.translateService.instant('document.nodeEditable.row_below'),
                                    },
                                    separator1: {
                                        name: '---------',
                                    },
                                    col_left: {
                                        name: this.translateService.instant('document.nodeEditable.col_left'),
                                    },
                                    col_right: {
                                        name: this.translateService.instant('document.nodeEditable.col_right'),
                                    },
                                    separator2: {
                                        name: '---------',
                                    },
                                    remove_row: {
                                        name: this.translateService.instant('document.nodeEditable.remove_row'),
                                    },
                                    remove_col: {
                                        name: this.translateService.instant('document.nodeEditable.remove_col'),
                                    },
                                },
                            },
                            cells: (row, col, prop) => {
                                const cellProperties: any = {};

                                if (row === 0 && col === 0) {
                                    cellProperties.readOnly = true;
                                    cellProperties.renderer = function (
                                        instance,
                                        td,
                                        row,
                                        col,
                                        prop,
                                        value,
                                        cellProperties
                                    ) {
                                        td.style.background = '#f3f3f3';
                                    };
                                }

                                return cellProperties;
                            },
                        },
                    },
                },
                display: {
                    component: DisplayChartComponent,
                    config: { type: 'line-chart' },
                },
            },
            'bar-chart': {
                editor: {
                    component: EditTableComponent,
                    config: {
                        type: 'bar-chart',
                        settings: {
                            colHeaders: [
                                this.translateService.instant('document.nodeEditable.label'),
                                this.translateService.instant('document.nodeEditable.value'),
                            ],
                            rowHeaders: [this.translateService.instant('document.nodeEditable.legend')],
                            columns: [{ type: 'text' }, { type: 'text' }],
                            minRows: 2,
                            // contextMenu: ['row_above', 'row_below', '---------', 'remove_row'],
                            contextMenu: {
                                items: {
                                    row_above: {
                                        name: this.translateService.instant('document.nodeEditable.row_above'),
                                    },
                                    row_below: {
                                        name: this.translateService.instant('document.nodeEditable.row_below'),
                                    },
                                    separator: {
                                        name: '---------',
                                    },
                                    remove_row: {
                                        name: this.translateService.instant('document.nodeEditable.remove_row'),
                                    },
                                },
                            },
                        },
                    },
                },
                display: {
                    component: DisplayChartComponent,
                    config: { type: 'bar-chart' },
                },
            },
            'bubble-chart': {
                editor: {
                    component: EditTableComponent,
                    config: {
                        type: 'bubble-chart',
                        settings: {
                            colHeaders: [
                                this.translateService.instant('document.nodeEditable.group'),
                                this.translateService.instant('document.nodeEditable.name'),
                                this.translateService.instant('document.nodeEditable.x'),
                                this.translateService.instant('document.nodeEditable.y'),
                                this.translateService.instant('document.nodeEditable.z'),
                            ],
                            rowHeaders: [this.translateService.instant('document.nodeEditable.legend')],
                            columns: [
                                { type: 'text' },
                                { type: 'text' },
                                { type: 'text' },
                                { type: 'text' },
                                { type: 'text' },
                            ],
                            minRows: 2,
                            // contextMenu: ['row_above', 'row_below', '---------', 'remove_row'],
                            contextMenu: {
                                items: {
                                    row_above: {
                                        name: this.translateService.instant('document.nodeEditable.row_above'),
                                    },
                                    row_below: {
                                        name: this.translateService.instant('document.nodeEditable.row_below'),
                                    },
                                    separator: {
                                        name: '---------',
                                    },
                                    remove_row: {
                                        name: this.translateService.instant('document.nodeEditable.remove_row'),
                                    },
                                },
                            },
                        },
                    },
                },
                display: {
                    component: DisplayChartComponent,
                    config: { type: 'bubble-chart' },
                },
            },
            'map-chart': {
                editor: {
                    component: EditTableComponent,
                    config: {
                        type: 'map-chart',
                        settings: {
                            colHeaders: [
                                this.translateService.instant('document.nodeEditable.group'),
                                this.translateService.instant('document.nodeEditable.name'),
                                this.translateService.instant('document.nodeEditable.lat'),
                                this.translateService.instant('document.nodeEditable.lng'),
                                this.translateService.instant('document.nodeEditable.value'),
                            ],
                            rowHeaders: [this.translateService.instant('document.nodeEditable.legend')],
                            columns: [
                                { type: 'text' },
                                { type: 'text' },
                                { type: 'text' },
                                { type: 'text' },
                                { type: 'text' },
                            ],
                            minRows: 2,
                            // contextMenu: ['row_above', 'row_below', '---------', 'remove_row'],
                            contextMenu: {
                                items: {
                                    row_above: {
                                        name: this.translateService.instant('document.nodeEditable.row_above'),
                                    },
                                    row_below: {
                                        name: this.translateService.instant('document.nodeEditable.row_below'),
                                    },
                                    separator: {
                                        name: '---------',
                                    },
                                    remove_row: {
                                        name: this.translateService.instant('document.nodeEditable.remove_row'),
                                    },
                                },
                            },
                        },
                    },
                },
                display: {
                    component: DisplayChartComponent,
                    config: { type: 'map-chart' },
                },
            },
            'paired-bar-chart': {
                editor: {
                    component: EditTableComponent,
                    config: {
                        type: 'paired-bar-chart',
                        settings: {
                            colHeaders: [
                                this.translateService.instant('document.nodeEditable.label'),
                                this.translateService.instant('document.nodeEditable.value1'),
                                this.translateService.instant('document.nodeEditable.value2'),
                            ],
                            rowHeaders: [this.translateService.instant('document.nodeEditable.legend')],
                            minCols: 3,
                            minRows: 2,
                            contextMenu: {
                                items: {
                                    row_above: {
                                        name: this.translateService.instant('document.nodeEditable.row_above'),
                                    },
                                    row_below: {
                                        name: this.translateService.instant('document.nodeEditable.row_below'),
                                    },
                                    separator1: {
                                        name: '---------',
                                    },
                                    col_left: {
                                        name: this.translateService.instant('document.nodeEditable.col_left'),
                                    },
                                    col_right: {
                                        name: this.translateService.instant('document.nodeEditable.col_right'),
                                    },
                                    separator2: {
                                        name: '---------',
                                    },
                                    remove_row: {
                                        name: this.translateService.instant('document.nodeEditable.remove_row'),
                                    },
                                    remove_col: {
                                        name: this.translateService.instant('document.nodeEditable.remove_col'),
                                    },
                                },
                            },
                        },
                    },
                },
                display: {
                    component: DisplayChartComponent,
                    config: { type: 'paired-bar-chart' },
                },
            },
            'paired-bar-line-chart': {
                editor: {
                    component: EditTableComponent,
                    config: {
                        type: 'paired-bar-line-chart',
                        settings: {
                            colHeaders: [
                                this.translateService.instant('document.nodeEditable.label'),
                                this.translateService.instant('document.nodeEditable.value1'),
                                this.translateService.instant('document.nodeEditable.value2'),
                                this.translateService.instant('document.nodeEditable.value3'),
                            ],
                            rowHeaders: [
                                this.translateService.instant('document.nodeEditable.legend'),
                                this.translateService.instant('document.nodeEditable.type'),
                            ],
                            minCols: 4,
                            minRows: 4,
                            // contextMenu: ['row_above', 'row_below', '---------', 'col_left', 'col_right', '---------', 'remove_row', 'remove_col'],
                            contextMenu: {
                                items: {
                                    row_above: {
                                        name: this.translateService.instant('document.nodeEditable.row_above'),
                                    },
                                    row_below: {
                                        name: this.translateService.instant('document.nodeEditable.row_below'),
                                    },
                                    separator1: {
                                        name: '---------',
                                    },
                                    col_left: {
                                        name: this.translateService.instant('document.nodeEditable.col_left'),
                                    },
                                    col_right: {
                                        name: this.translateService.instant('document.nodeEditable.col_right'),
                                    },
                                    separator2: {
                                        name: '---------',
                                    },
                                    remove_row: {
                                        name: this.translateService.instant('document.nodeEditable.remove_row'),
                                    },
                                    remove_col: {
                                        name: this.translateService.instant('document.nodeEditable.remove_col'),
                                    },
                                },
                            },
                            cells: (row, col, prop) => {
                                const cellProperties: any = {};

                                if ((row === 0 && col === 0) || (row === 1 && col === 0)) {
                                    cellProperties.readOnly = true;
                                    cellProperties.renderer = function (
                                        instance,
                                        td,
                                        row,
                                        col,
                                        prop,
                                        value,
                                        cellProperties
                                    ) {
                                        td.style.background = '#f3f3f3';
                                    };
                                }
                                return cellProperties;
                            },
                        },
                    },
                },
                display: {
                    component: DisplayChartComponent,
                    config: { type: 'paired-bar-line-chart' },
                },
            },
            'accumulated-bar-chart': {
                editor: {
                    component: EditTableComponent,
                    config: {
                        type: 'accumulated-bar-chart',
                        settings: {
                            colHeaders: [this.translateService.instant('document.nodeEditable.xaxis')],
                            rowHeaders: [this.translateService.instant('document.nodeEditable.legend')],
                            minRows: 2,
                            minCols: 3,
                            contextMenu: {
                                items: {
                                    row_above: {
                                        name: this.translateService.instant('document.nodeEditable.row_above'),
                                    },
                                    row_below: {
                                        name: this.translateService.instant('document.nodeEditable.row_below'),
                                    },
                                    separator1: {
                                        name: '---------',
                                    },
                                    col_left: {
                                        name: this.translateService.instant('document.nodeEditable.col_left'),
                                    },
                                    col_right: {
                                        name: this.translateService.instant('document.nodeEditable.col_right'),
                                    },
                                    separator2: {
                                        name: '---------',
                                    },
                                    remove_row: {
                                        name: this.translateService.instant('document.nodeEditable.remove_row'),
                                    },
                                    remove_col: {
                                        name: this.translateService.instant('document.nodeEditable.remove_col'),
                                    },
                                },
                            },
                            cells: (row, col, prop) => {
                                const cellProperties: any = {};

                                if (row === 0 && col === 0) {
                                    cellProperties.readOnly = true;
                                    cellProperties.renderer = function (
                                        instance,
                                        td,
                                        row,
                                        col,
                                        prop,
                                        value,
                                        cellProperties
                                    ) {
                                        td.style.background = '#f3f3f3';
                                    };
                                }

                                return cellProperties;
                            },
                        },
                    },
                },
                display: {
                    component: DisplayChartComponent,
                    config: { type: 'accumulated-bar-chart' },
                },
            },
            'pie-chart': {
                editor: {
                    component: EditTableComponent,
                    config: {
                        type: 'pie-chart',
                        settings: {
                            colHeaders: [
                                this.translateService.instant('document.nodeEditable.label'),
                                this.translateService.instant('document.nodeEditable.value'),
                            ],
                            columns: [{ type: 'text' }, { type: 'text' }],
                            minRows: 1,
                            contextMenu: {
                                items: {
                                    row_above: {
                                        name: this.translateService.instant('document.nodeEditable.row_above'),
                                    },
                                    row_below: {
                                        name: this.translateService.instant('document.nodeEditable.row_below'),
                                    },
                                    separator: {
                                        name: '---------',
                                    },
                                    remove_row: {
                                        name: this.translateService.instant('document.nodeEditable.remove_row'),
                                    },
                                },
                            },
                        },
                    },
                },
                display: {
                    component: DisplayChartComponent,
                    config: { type: 'pie-chart' },
                },
            },
            'excel-line-chart': {
                editor: {
                    component: DisplayExcelChartComponent,
                },
                display: {
                    component: DisplayExcelChartComponent,
                },
            },
            'excel-bar-chart': {
                editor: {
                    component: DisplayExcelChartComponent,
                },
                display: {
                    component: DisplayExcelChartComponent,
                },
            },
            'excel-bubble-chart': {
                editor: {
                    component: DisplayExcelChartComponent,
                },
                display: {
                    component: DisplayExcelChartComponent,
                },
            },
            'excel-map-chart': {
                editor: {
                    component: DisplayExcelChartComponent,
                },
                display: {
                    component: DisplayExcelChartComponent,
                },
            },
            'excel-paired-bar-chart': {
                editor: {
                    component: DisplayExcelChartComponent,
                },
                display: {
                    component: DisplayExcelChartComponent,
                },
            },
            'excel-paired-bar-line-chart': {
                editor: {
                    component: DisplayExcelChartComponent,
                },
                display: {
                    component: DisplayExcelChartComponent,
                },
            },
            'excel-accumulated-bar-chart': {
                editor: {
                    component: DisplayExcelChartComponent,
                },
                display: {
                    component: DisplayExcelChartComponent,
                },
            },
            'excel-pie-chart': {
                editor: {
                    component: DisplayExcelChartComponent,
                },
                display: {
                    component: DisplayExcelChartComponent,
                },
            },
            line: {
                editor: {
                    component: DisplayLineComponent,
                    config: {
                        type: 'line',
                        settings: {},
                    },
                },
                display: {
                    component: DisplayLineComponent,
                    config: {
                        type: 'line',
                    },
                },
            },
            'two-cols-start': {
                editor: {
                    component: DisplayTwoColsStartComponent,
                    config: {
                        type: 'two-cols-start',
                        settings: {},
                    },
                },
                display: {
                    component: DisplayTwoColsStartComponent,
                    config: { type: 'two-cols-start' },
                },
            },
            'two-cols-end': {
                editor: {
                    component: DisplayTwoColsEndComponent,
                    config: {
                        type: 'two-cols-end',
                        settings: {},
                    },
                },
                display: {
                    component: DisplayTwoColsEndComponent,
                    config: { type: 'two-cols-end' },
                },
            },
            'page-break': {
                editor: {
                    component: DisplayPageBreakComponent,
                    config: {
                        type: 'page-break',
                        settings: {},
                    },
                },
                display: {
                    component: DisplayPageBreakComponent,
                    config: { type: 'page-break' },
                },
            },
            'col-break': {
                editor: {
                    component: DisplayColBreakComponent,
                    config: {
                        type: 'col-break',
                        settings: {},
                    },
                },
                display: {
                    component: DisplayColBreakComponent,
                    config: { type: 'col-break' },
                },
            },
            'table-of-content': {
                editor: {
                    component: DisplayTableOfContentComponent,
                    config: {
                        type: 'table-of-content',
                        settings: {},
                    },
                },
                display: {
                    component: DisplayTableOfContentComponent,
                    config: { type: 'table-of-content' },
                },
            },
            unknown: {
                editor: defaultNodeComponent,
                display: defaultNodeComponent,
            },
        };
    }
}

export const defaultNodeComponent: NodeComponent = {
    component: UnknownNodeComponent,
    config: { type: 'unknown' },
};
